<template>

<app-content-box :is-empty="!data.length" :is-grid="true" empty-message="No winners announced yet." class="winners" title="Winners" :transparent="data.length" icon="winner" :not-padded="true">

	<app-card v-for="item in data" :not-linked="true" :key="item.id" :image="item.prize.image" imageIcon="prize" :title="item.prize.name" :subtitle="'Won by ' + item.name" />

</app-content-box>

</template>

<script>

export default {
	
	name: 'winners',

	props: ['data']

}

</script>

<style scoped>

.winners {
	grid-column: 1 / 5;
}

.winners >>> .box-content {
	min-height: 160px;
}

</style>
